import $ from 'jquery';

export default function accordions() {

    // accordion
    $('.accordion-title').on('click', function() {
        if($(this).parent().hasClass('is-active')) {
            $('.accordion-title').parent().removeClass('is-active');
            $('.accordion-desc').slideUp(200);
        } else {
            $('.accordion-title').parent().removeClass('is-active');
            $('.accordion-desc').slideUp(200);

            $(this).parent().addClass('is-active');
            $(this).next('.accordion-desc').slideDown(200);
        }
    });

    // Side accordion

    function firstLevel(element) {
        if(element.hasClass('is-visible')) {
            $('.sidemenu-submenu').removeClass('is-visible').slideUp(200);
        } else {
            $('.sidemenu-submenu').removeClass('is-visible').slideUp(200);
            element.addClass('is-visible').slideDown(200);
        }
    }

    function secondLevel(element) {
        if(element.hasClass('is-visible')) {
            $('.sidemenu-subsubmenu').removeClass('is-visible').slideUp(200);
        } else {
            $('.sidemenu-subsubmenu').removeClass('is-visible').slideUp(200);
            element.addClass('is-visible').slideDown(200);
        }
    }

    $('.sidemenu-link').on('click', function() {
        var element = $(this).next('.sidemenu-submenu');
        if(element.length) {
            firstLevel(element);
            return false;
        }
    });

    $('.sidemenu-sublink').on('click', function() {
        var element = $(this).next('.sidemenu-subsubmenu');
        if(element.length) {
            secondLevel(element);
            return false;
        }
    });

}