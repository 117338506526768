import Flickity from 'flickity-imagesloaded';

export default function sliders() {

    const homeSliderContainer = document.getElementById('header-slider');

    if(homeSliderContainer) {
        var homeSlider = new Flickity( homeSliderContainer, {
            cellAlign: 'left',
            cellSelector: '.carousel-cell',
            wrapAround: true,
            pageDots: true,
            prevNextButtons: false,
            autoPlay: 6000
            // pauseAutoPlayOnHover: false,
            // arrowShape: {
            //     x0: 10,
            //     x1: 60, y1: 55,
            //     x2: 65, y2: 45,
            //     x3: 25
            // }
        });
    }
}
