import $ from 'jquery';
import magnificPopup from 'magnific-popup';

export default function magnific() {

    // Translation
    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'X (Esc)',
        tLoading: '...',
        gallery: {
            tPrev: '&#8592;',
            tNext: '&#8594;',
            tCounter: '%curr% / %total%'
        },
        image: {
            tError: 'x-x'
        },
        ajax: {
            tError: 'x-x'
        }
    });


    // Initialize gallery
    $('.js-content-gallery').each(function() {
        $(this).magnificPopup({
            delegate: '.magnific-thumb',
            showCloseBtn: true,
            type: 'image',
            gallery: {
                enabled: true,
                preload: [0,2]
            },
            image: {
                markup:
                    '<div class="mfp-figure mfp-figure-gallery">' +
                    '<div class="mfp-custom-header">' +
                    '<div class="mfp-custom-title">' +
                    '<div class="mfp-counter"></div>' +
                    '<div class="mfp-title"></div>' +
                    '</div>' +
                    '<div class="mfp-close">X</div>' +
                    '</div>' +
                    '<div class="mfp-custom-holder">' +
                    '<div class="mfp-img"></div>' +
                    '</div>' +
                    '<div class="mfp-bottom-bar">' +
                    '<div class="mfp-title"></div>' +
                    '</div>' +
                    '</div>',
                titleSrc: function (item) {
                    if (item.el.find('figcaption').html() === undefined) {
                        return '<small>&nbsp;</small>';
                    } else {
                        return item.el.find('figcaption').html();
                    }
                }
            }
        });
    });


    // Inline
    if ($('.magnific-inline').length) {
        $('.magnific-inline').magnificPopup({
            type: 'inline',
            gallery: {
                enabled: true
            }
        });
    }

    // Image Pressreview
    if ($('.magnific-press').length) {
        $('.magnific-press').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            },
            image: {
                verticalFit: false, // Fits image in area vertically
            },
        });
    }

    // Pano
    if ($('.magnific-pano').length) {
        $('.magnific-pano').magnificPopup({
            type: 'iframe',
            iframe: {
                markup: '<div class="mfp-iframe-scaler">'+
                '<div class="mfp-custom-header">' +
                '<div class="mfp-custom-title">' +
                '<div class="mfp-counter"></div>' +
                '<div class="mfp-title"></div>' +
                '</div>' +
                '<button title="X (Esc)" type="button" class="mfp-close">x</button>' +
                '</div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>'
            },
            callbacks: {
                markupParse: function(template, values, item) {
                    values.title = item.el.attr('title');
                }
            },
            gallery: {
                enabled: true
            }
        });
    }

    // Media: Video
    if ($('.magnific-video').length) {
        $('.magnific-video').magnificPopup({
            type: 'iframe',
            iframe: {
                markup: '<div class="mfp-iframe-scaler">'+
                '<div class="mfp-custom-header">' +
                '<div class="mfp-custom-title">' +
                '<div class="mfp-counter"></div>' +
                '<div class="mfp-title"></div>' +
                '</div>' +
                '<button title="X (Esc)" type="button" class="mfp-close">x</button>' +
                '</div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>'
            },
            callbacks: {
                markupParse: function(template, values, item) {
                    values.title = item.el.attr('title');
                }
            },
            gallery: {
                enabled: false
            }
        });
    }
}