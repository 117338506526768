import init from "../vbcn";

const vbcnOptions = {
	notice: {
		ignoredPages: [
			'/no-data'
		],
		ignoredHashes: [
			'#hide-my-stuff',
		],
		declineMode: 'session',
		centerMode: false,
		useBackdrop: false,
	},
	texts: {
		"de": {
			explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. <br> " +
				"Zur Analyse der Zugriffe auf unsere Website verwenden wir Matomo Analytics. <br>" +
				"Außerdem binden wir Skripte von YouTube und Vimeo, sowie des DJH ein. <br>" +
				"Dabei können personenbezogene Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
			configuration_explanation_headline: "Was ist ein Cookie?",
			configuration_explanation_text: "Unter <strong>Cookie</strong> versteht man eine kleine <strong>Datei</strong>, beim Besuch von Internetseiten auf Deinem Computer oder Smartphone gespeichert wird. Besuchst Du die Website zu einem späteren Zeitpunkt wieder, wird der gespeicherte Cookie an den Webserver übertragen.",
		},
		"en": {
			explanationText: "On your consent cookies are set to optimize your experience on our website. <br>" +
				"We use Matomo Analytics to analyze the usage  of our website. <br>" +
				"We embed scripts from these third party service providers:  YouTube, Vimeo and the DJH <br>" +
				"On activation of one or more of these services personal data might be forwarded to these partners. Further information you find <a href='/datenschutz'>here</a> in our privacy statement.",
			configuration_explanation_headline: "What is a cookie?",
			configuration_explanation_text: "A <strong>cookie</strong> is a small <strong>data file</strong> that is stored when visiting a website on your computer or smartphone. As a result, the stored cookie will be transmitted to the web server each time you visit the website again.",
		},
	},
	sets: {
		"de": [
			{
				id: 'mandatory',
				name: 'Notwendige Cookies',
				description: 'Diese Cookies sind fÃ¼r die einwandfreie Funktion und das Design unserer Seiten nÃ¶tig. Sie speichern keine personenbezogenen Daten.',
				mandatory: true,
				mandatory_text: "Diese Cookies sind notwendig, um Ihre Einstellungen zu speichern."
			}, {
				id: 'analytics',
				name: 'Matomo Analytics',
				description: 'Diese Skripte und Cookies werden eingebunden, um mehr Ã¼ber die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, BildschirmauflÃ¶sung oder verwendeter Browser.'
			}, {
				id: 'maps',
				name: 'Google Maps',
				description: 'Diese Skripte und Cookies sind nÃ¶tig, um die Karten des Dienstes Google Maps darzustellen.'
			}, {
				id: 'timify',
				name: 'Buchung Massage',
				description: 'Diese Skripte und Cookies sind nÃ¶tig, um die Buchung mit dem Dienst Timify durchzufÃ¼hren.'
			}, {
				id: 'opentable',
				name: 'Open Table',
				description: 'Diese Skripte und Cookies sind nÃ¶tig, um die Reservierungs-Funktion darzustellen. Durch das Aktivieren kÃ¶nnen personenbezogene Daten an OpenTable Ã¼bertragen werden.'
			}, {
				id: 'selected',
				name: 'Selected',
				description: 'Diese Skripte und Cookies sind nÃ¶tig, um MÃ¼nchner StÃ¤dtetouren anzuzeigen. Durch das Aktivieren kÃ¶nnen personenbezogene Daten an OpenTable Ã¼bertragen werden.'
			}],
		"en": [
			{
				id: 'mandatory',
				name: 'Mandatory cookies',
				description: 'Cookies that ensure the functionality and the design of our webpages. No personal data are collected. \nThese cookies are required to save your session settings.',
				mandatory: true,
				mandatory_text: "This must be checked."
			}, {
				id: 'analytics',
				name: 'Matomo Analytics',
				description: 'Scripts and cookies that gather information about visitors of the site, for example country of origin, device settings and browsers.'
			}, {
				id: 'maps',
				name: 'Google Maps',
				description: 'Scripts and cookies that are required to display Google Maps. On activation personal data might be forwarded to Google.'
			}, {
				id: 'timify',
				name: 'Booking timifiy',
				description: 'Scripts and cookies that are required to provide the booking-service timify. On activation personal data might be forwarded to Google.'
			}, {
				id: 'opentable',
				name: 'Open Table',
				description: 'Scripts and cookies that are required to make Reservations. On activation personal data might be forwarded to OpenTable.'
			}, {
				id: 'selected',
				name: 'Selected',
				description: 'Scripts and cookies that are required to make Reservations. On activation personal data might be forwarded to Selected.'
			}]
	}
}

export function initCookieNotice() {
	window.myVBCN = init(vbcnOptions);
}
