import moment from 'moment';
import $ from 'jquery';
import daterangepicker from 'daterangepicker';
import matchHeight from 'jquery-match-height';

export default function snippets() {

    // Scroll down - arrow
    $('.slider-arrow').on('click', function () {
        $('html, body').animate({
            scrollTop: $('#main-content').offset().top - 80
        }, 500, 'swing');
    });

    // Add class on scroll
    const header = $('.header');
    const menuTrigger = $('.menu-trigger');

    $(window).on('scroll', function () {
        var scroll = $(window).scrollTop();

        if (!menuTrigger.hasClass('is-clicked')) {
            if (scroll >= 200) {
                header.addClass('header-scrolled');
            } else {
                header.removeClass('header-scrolled');
            }
        } else {

        }
    });

    // Menu Logic
    menuTrigger.on('click', function () {
        if ($(this).hasClass('is-clicked')) {
            $(this).removeClass('is-clicked');
            $('.mobile-menu').removeClass('is-visible');
            if ($(window).scrollTop() < 200) {
                header.removeClass('header-scrolled');
            }
        } else {
            $(this).addClass('is-clicked');
            $('.header').addClass('header-scrolled');
            $('.mobile-menu').addClass('is-visible');
        }
    });

    // Scroll to top on click
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
        /*        if ($('#back-to-top').css("display") != "none") {
if ($(document).scrollTop() > 50) {
            $('#back-to-top').css("visibility", "visible");
        } else {
            $('#back-to-top').css("visibility", "hidden");
        }
    }*/
    });
    $('#back-to-top').click(function () {
        $('body,html').animate({scrollTop: 0}, 800);
        return false;
    });


    // Match Height
    $('.thumbs-content, .content-thumb-title, .content-price-inner, .news-block').matchHeight();

    // ======================= Booking Form Stuff ============================================
    let startDate;
    let endDate;
    let culture;
    let rate;
    let nights = 1;
    let adults = 1;
    let rooms = 1;
    // let bookingUrl = 'https://fideliosuite8webconnect.com/DE-Deutsche-Eiche-Muenchen/Inquiry.aspx';
    let bookingUrl = 'https://booking.deutsche-eiche.de/v8client/Inquiry.aspx';
     if ($("#FormBuilder-form_booking").length) {
        var $form = $('#FormBuilder-form_booking');
        // console.log ($form.attr('action'));
        bookingUrl = $form.attr('action');
        //$form.removeAttr('onsubmit');
        $form.submit(function (ev) {

            culture = $('input[name="culture"]').val();
            adults = $('input[name="adults"]').val();
            rooms = $('input[name="rooms"]').val();

            bookingUrl += '?language=' + culture;
            bookingUrl += '&checkin=' + startDate;
            bookingUrl += '&checkout=' + endDate;
            // bookingUrl += '&rooms=' + rooms;
            bookingUrl += '&persons=' + adults;
            bookingUrl += '&currency=EUR';

            // console.log(bookingUrl);
            window.open(bookingUrl, '_blank');
            bookingUrl =  $form.attr('action'); //'https://booking.deutsche-eiche.de/v8client/Inquiry.aspx';
            $('html').css('overflow-y', 'auto');
            $('#header').removeClass('header-reduced');
            $('.form-overlay').fadeOut(150);


        });
    }

    // Stuff for booking form
    // $('#Inputfield_culture').val(document.documentElement.lang);

    var picker = $('#date_trigger').daterangepicker({
        "parentEl": "#append_form",
        "autoApply": true,
        "minDate": new Date(),
        locale: {
            format: 'DD.MM.YYYY',
            cancelLabel: 'Clear'
        }
    });

    function currentDate() {
        var current = new Date();
        var dd = String(current.getDate()).padStart(2, '0');
        var mm = String(current.getMonth() + 1).padStart(2, '0');
        var yyyy = current.getFullYear();
        startDate = mm + '.' + dd + '.' + yyyy;
    }

    currentDate();

    picker.on('apply.daterangepicker', function(ev, picker) {

        // dates to calculate number of nights
        var start = moment(picker.startDate);
        var end   = moment(picker.endDate);
        var diff = start.diff(end, 'days');

        // dates format to display
        // startDate = start.format('DD.MM.YYYY');
        // endDate = end.format('DD.MM.YYYY');
        startDate = start.format('YYYY-MM-DD');
        endDate = end.format('YYYY-MM-DD');


        nights = Math.abs(diff);
        $('#Inputfield_nights').val(Math.abs(diff));
        $(this).val(startDate + ' - ' + endDate);
        $('#Inputfield_datefilter').val(startDate + ' - ' + endDate);
    });

    // prevent hide after range selection && how picker on load
    picker.data('daterangepicker').hide = function () {};
    picker.data('daterangepicker').show();

    // Open Booking Overlay
    $('.js-link-book').on('click', function() {
        $('html').css('overflow-y', 'hidden');
        $('#header').addClass('header-reduced');
        $('.form-overlay').fadeIn(150);
        return false;
    });

    // Close Booking Overlay
    $('.form-overlay-close').on('click', function() {
        $('html').css('overflow-y', 'auto');
        $('#header').removeClass('header-reduced');
        $('.form-overlay').fadeOut(150);
    });

    // --- Audio-Steuerung --- //
    var obj = document.createElement("audio");
    obj.volume = 0.5;
    obj.autoPlay = false;
    obj.preLoad = true;
    obj.controls = true;

    $('.audiotrigger').click(function (event) {
        if (!$(this).hasClass('audioactive')) {
            if ($('.audioactive').length) {
                $('.audioactive').removeClass('audioactive');
            }

            $(this).append(obj);
            obj.src = $(this).attr('data-file');
            obj.controls = true;
            obj.play();
            $(this).addClass('audioactive');
        }
    });

    $('.videotrigger').click(function (event) {
        obj.pause();
        obj.currentTime = 0;
        // $('.audioactive').removeClass('audioactive');
    });
}

function checkMaps() {
    if(window.cookieState && window.cookieState.cookiesAllowed && window.cookieState.allowedData.options.hasOwnProperty('maps') && window.cookieState.allowedData.options['maps'] ) {
        $('.maps-embed').each(function(index) {

            var iframe = $(this).find('iframe');

            $(this).show();
            iframe.attr('src', iframe.attr('data-src'));
        });
    }
}

export function initMaps() {
    // console.log("initMaps");
    checkMaps();
    $('.js-vbcn-execute, #vbcn-accept-preset, #vbcn-save').on('click', initMaps);
}

function checkTimify() {
    if(window.cookieState && window.cookieState.cookiesAllowed && window.cookieState.allowedData.options.hasOwnProperty('timify') && window.cookieState.allowedData.options['timify'] ) {
        $('.timify-embed').each(function(index) {

            var iframe = $(this).find('iframe');

            $(this).show();
            iframe.attr('src', iframe.attr('data-src'));
        });
    }
}

export function initTimify() {
    // console.log("initMaps");
    checkTimify();
    $('.js-vbcn-execute, #vbcn-accept-preset, #vbcn-save').on('click', initTimify);
}

