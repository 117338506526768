// Imports
import {initCookieNotice} from "./components/cookie-notice";
import snippets from './components/snippets';
import { initMaps, initTimify } from './components/snippets';
import sliders from './components/sliders';
import accordions from './components/accordions';
import magnific from './components/magnific';

// Website id ready
document.addEventListener('DOMContentLoaded', function() {
	snippets();
    sliders();
	accordions();
    magnific();
	initCookieNotice();
    initMaps();
    initTimify();
});
